import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiCall, logoutUser } from '../../../API';
import { setLocalStore } from '../../../localStorage'

export const loginACTION = createAsyncThunk(
    'Authentication/login',
    async (data) => {
        return apiCall('/user/login', 'POST', data, false)
            .then((response) => {

                if (response.status === 200 || response.status === 201) {
                    if (!response.data.hasError) {
                        localStorage.setItem(
                            process.env.REACT_APP_TOKEN_AUTHENTICATION_KEY,
                            response.data.data.tokenDetails.token
                        );
                        localStorage.setItem(
                            "usersInfo",
                            JSON.stringify(response.data.data)
                        );
                        return {
                            ...response.data
                        }
                    } else {
                        //console.log('response error',response.data.message)
                        return Promise.reject({
                            ...response.data.message
                        })
                    }

                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error
                })
            })

    }
)


export const autologinACTION = createAsyncThunk(
    'Authentication/autologin',
    async (data) => {
        return apiCall('/user/autologin', 'POST', data, false)
            .then((response) => {

                if (response.status === 200 || response.status === 201) {
                    if (!response.data.hasError) {
                        localStorage.setItem(
                            process.env.REACT_APP_TOKEN_AUTHENTICATION_KEY,
                            response.data.data.tokenDetails.token
                        );
                        if(data.dt){
                            response.data.data.lgnType = data.dt;
                            localStorage.setItem(
                                "rid",
                                data.rid
                            );
                        }
                      
                        localStorage.setItem(
                            "usersInfo",
                            JSON.stringify(response.data.data)
                        );
                       
                        return {
                            ...response.data
                        }
                    } else {
                        //console.log('response error',response.data.message)
                        return Promise.reject({
                            ...response.data.message
                        })
                    }

                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error
                })
            })

    }
)

export const logoutACTION = createAsyncThunk(
    'Authentication/logout',
    async (data) => {
        return logoutUser()
            .then((response) => {

                return response;

            })


    }
)

