import { ReducerStateContant } from "../constants/general-const";
import _ from "lodash";

function StateCompleted(stateObject) {
    if (stateObject.apiMsg.status === ReducerStateContant.fulfilled) {
        return true;
    }
    else {
        return false;
    }
};

function StatePending(stateObject) {
    if (stateObject.apiMsg.status === ReducerStateContant.pending) {
        return true;
    }
    else {
        return false;
    }
};

function StateRejected(stateObject) {
    if (stateObject.apiMsg.status === ReducerStateContant.rejected) {
        return true;
    }
    else {
        return false;
    }
};

function StateHasRecords(stateObject) {
    return StateHasData(stateObject) && stateObject.data.records && stateObject.data.records.length;
};
function StateHasRecordsAvailable(stateObject) {
    return stateObject && stateObject.data && stateObject.data.records.length > 0;
};
function StateHasData(stateObject) {
    return stateObject && stateObject.data;
};

function StateHasDataLenght(stateObject) {
    return stateObject && stateObject.data && stateObject.data.length;
};

function GetLookupValue(stateObject, lookupType) {
    let values = []
    if (stateObject && stateObject.data && stateObject.data.length) {
        var lookupData = _.find(stateObject.data, { lookupType: lookupType });
        values = lookupData ? lookupData.data : [];
    }
    return values || [];
};
function CheckPermission(permissionName) {
    const user = JSON.parse(localStorage.getItem("usersInfo")).user;
    if (user.roles && user.roles.length && IsOwnerAdminOrSupportUser(user.roles[0])) {
        return true;
    }
    else {
        if (user.permissions && user.permissions.indexOf(permissionName) != "-1") {
            return true;
        }
        else {
            return false;
        }
    }
}
function CreateArrayOfNumbers(minValue, maxValues) {

    return [...Array((maxValues + 1) - minValue).keys()].map((i) => {
        return (minValue + i)
    });
}

function IsOwnerAdminOrSupportUser(role) {
    const allowedRoles = ["Owner", "Admin", "SupportUser"];
    return allowedRoles.includes(role);
}

export {
    StateCompleted,
    StateRejected,
    StateHasRecords,
    StateHasData,
    StateHasDataLenght,
    StateHasRecordsAvailable,
    GetLookupValue,
    StatePending,
    CheckPermission,
    CreateArrayOfNumbers,
    IsOwnerAdminOrSupportUser
};