import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiCall } from "../../../API";
import * as Toast from "../../../component/Toast"
import { Enums } from "../../../helper/constants/emuns-const";

export const productMasterBulkGetAllACTION = createAsyncThunk(
    "ProductMaster/productMasterBulkGetAllACTION",
    async (data) => {
      let d = {
        device: 1,
        pageNo: -1,
        pageSize: 0,
        sortBy: 0,
        sortOrder: 0,
        filter: {
          ...data.filter
        },
        restaurantID: data.restaurantID,
      };
  
      return apiCall("/productmaster/bulk/getall", "POST", d, false)
        .then((response) => {
          if (response.status === 200) {
            if (!response.data.hasError) {
              //console.log("response.data ", response.data);
  
              return {
                ...response.data,
                data: {
                  ...response.data.data,
  
                },
              };
            } else {
              //console.log("response error", response.data.message);
              Toast.error(response.data.message.message)
              return Promise.reject({
                ...response.data.message,
              });
            }
          }
        })
        .catch((error) => {
          Toast.error(error)
          return Promise.reject({
            ...error,
          });
        });
    }
  );

  export const productMasterBulkUpdateACTION = createAsyncThunk(
    "ProductMaster/productMasterBulkUpdateACTION",
    async (data) => {
      return apiCall("/productmaster/bulk/update", "POST", data, false)
        .then((response) => {
          if (response.status === 200) {
            if (!response.data.hasError) {
              if (data.statusType !== Enums.ChangeStatus.SortOrder) {
                Toast.success(response.data.message.message)
              }
              return {
                ...response.data,
                id: data.id,
              };
            } else {
              //console.log("response error", response.data.message);
              Toast.error(response.data.message.message)
              return Promise.reject({
                ...response.data.message,
              });
            }
          }
        })
        .catch((error) => {
          Toast.error(error)
          return Promise.reject({
            ...error,
          });
        });
    }
  );
  
  