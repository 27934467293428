import { createSlice } from "@reduxjs/toolkit";
import { getallReceiptPrinterACTION, getReceiptPrinterACTION, insertUpdateReceiptPrinterAction, receiptPrinterChangeStatusACTION, receiptPrinterDeleteACTION } from "../../actions/settings/receiptPrinterAction";

const INITIAL_STATE = {
    receiptPrinters: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    receiptPrinterDetails: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    selectedReceiptPrinterDetails: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    insertUpdateReceiptPrinterDetails: {
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    }
};

const receiptPrinterReducer = createSlice({
    name: "ReceiptPrinter",
    initialState: INITIAL_STATE,
    reducers: {
        RESET(state, action) {
            state = INITIAL_STATE;
        },
        ResetReceiptPrinterDetailsState(state, action) {
            state.receiptPrinterDetails = INITIAL_STATE.receiptPrinterDetails;
            state.insertUpdateReceiptPrinterDetails = INITIAL_STATE.insertUpdateReceiptPrinterDetails;
            state.selectedReceiptPrinterDetails = INITIAL_STATE.selectedReceiptPrinterDetails
        },
    },
    extraReducers: (builder) => {
        //------------------------------- insertUpdateReceiptPrinterAction start -------------------------
        // eslint-disable-next-line
        builder.addCase(insertUpdateReceiptPrinterAction.fulfilled, (state, action) => {
            // console.log("insertUpdateReceiptPrinterAction", action);

            //state.receiptPrinters.data.records.push(action.payload.data);
            if (!action.payload.newRecord) {
                let updatedRecord = state.receiptPrinters.data.records.map(x => {
                    if (x.id == action.meta.arg.id) {
                        return action.meta.arg;
                    }
                    else {
                        return x;
                    }
                })

                state.receiptPrinters.data.records = updatedRecord;
            }
            else {
                if (state.receiptPrinters.data.records.length) {
                    state.receiptPrinters.data.records.unshift(action.payload.data.data);
                }
                else {
                    state.receiptPrinters.data.records.push(action.payload.data.data);
                }
            }

            state.insertUpdateReceiptPrinterDetails.apiMsg.status = action.meta.requestStatus;
            state.insertUpdateReceiptPrinterDetails.apiMsg.message = action.payload.message.message;
        }),
            builder.addCase(insertUpdateReceiptPrinterAction.pending, (state, action) => {
                state.insertUpdateReceiptPrinterDetails.apiMsg.status = action.meta.requestStatus;
            }),
            builder.addCase(insertUpdateReceiptPrinterAction.rejected, (state, action) => {
                state.insertUpdateReceiptPrinterDetails.apiMsg.message = action.error.message;
                state.insertUpdateReceiptPrinterDetails.apiMsg.status = action.meta.requestStatus;
            }),

            //------------------------------- insertUpdateReceiptPrinterAction start -------------------------

            //------------------------------- getallReceiptPrinterACTION start -------------------------

            builder.addCase(getallReceiptPrinterACTION.fulfilled, (state, action) => {
                // console.log("getallReceiptPrinterACTION", action);

                state.receiptPrinters.data = action.payload.data;
                state.receiptPrinters.apiMsg.status = action.meta.requestStatus;
                state.receiptPrinters.apiMsg.message = action.payload.message.message;
            }),
            builder.addCase(getallReceiptPrinterACTION.pending, (state, action) => {
                //console.log("getallReceiptPrinterACTION pending", action);
                state.receiptPrinters.apiMsg.status = action.meta.requestStatus;
            }),
            builder.addCase(getallReceiptPrinterACTION.rejected, (state, action) => {
                //console.log("getallReceiptPrinterACTION rejected", action);
                state.receiptPrinters.apiMsg.message = action.error.message;
                state.receiptPrinters.apiMsg.status = action.meta.requestStatus;
            }),
            //------------------------------- getallReceiptPrinterACTION end -------------------------

            //------------------------------- receiptPrinterChangeStatusACTION start -------------------------

            builder.addCase(receiptPrinterChangeStatusACTION.fulfilled, (state, action) => {
                //console.log("receiptPrinterChangeStatusACTION", action);

                state.receiptPrinters.data.records = state.receiptPrinters.data.records.map((item) => {

                    return {
                        ...item,
                        active: action.payload.id === item.id ? !item.active : item.active
                    }
                })
                state.receiptPrinterDetails.apiMsg.status = action.meta.requestStatus;
                state.receiptPrinterDetails.apiMsg.message = action.payload.message.message;
            }),
            builder.addCase(receiptPrinterChangeStatusACTION.pending, (state, action) => {
                //console.log("receiptPrinterChangeStatusACTION pending", action);
                state.receiptPrinterDetails.apiMsg.status = action.meta.requestStatus;
            }),
            builder.addCase(receiptPrinterChangeStatusACTION.rejected, (state, action) => {
                //console.log("receiptPrinterChangeStatusACTION rejected", action);
                state.receiptPrinterDetails.apiMsg.message = action.error.message;
                state.receiptPrinterDetails.apiMsg.status = action.meta.requestStatus;
            }),
            //------------------------------- receiptPrinterChangeStatusACTION end -------------------------

            //------------------------------- receiptPrinterDeleteACTION start -------------------------

            builder.addCase(receiptPrinterDeleteACTION.fulfilled, (state, action) => {
                //console.log("receiptPrinterDeleteACTION", action);

                state.receiptPrinters.data.records = state.receiptPrinters.data.records.filter((item, index) => item.id !== action.payload.data.id)

                state.receiptPrinterDetails.apiMsg.status = action.meta.requestStatus;
                state.receiptPrinterDetails.apiMsg.message = action.payload.message.message;
            }),
            builder.addCase(receiptPrinterDeleteACTION.pending, (state, action) => {
                //console.log("receiptPrinterDeleteACTION pending", action);
                state.receiptPrinterDetails.apiMsg.status = action.meta.requestStatus;
            }),
            builder.addCase(receiptPrinterDeleteACTION.rejected, (state, action) => {
                //console.log("receiptPrinterDeleteACTION rejected", action);
                state.receiptPrinterDetails.apiMsg.message = action.error.message;
                state.receiptPrinterDetails.apiMsg.status = action.meta.requestStatus;
            }),
            //------------------------------- receiptPrinterDeleteACTION end -------------------------

            //------------------------------- getReceiptPrinterACTION start -------------------------

            builder.addCase(getReceiptPrinterACTION.fulfilled, (state, action) => {
                //console.log("getReceiptPrinterACTION", action);
                state.selectedReceiptPrinterDetails.data = action.payload.data;
                state.selectedReceiptPrinterDetails.apiMsg.status = action.meta.requestStatus;
                state.selectedReceiptPrinterDetails.apiMsg.message = action.payload.message.message;
            }),
            builder.addCase(getReceiptPrinterACTION.pending, (state, action) => {
                //console.log("getReceiptPrinterACTION pending", action);
                state.selectedReceiptPrinterDetails.apiMsg.status = action.meta.requestStatus;
            }),
            builder.addCase(getReceiptPrinterACTION.rejected, (state, action) => {
                //console.log("getReceiptPrinterACTION rejected", action);
                state.selectedReceiptPrinterDetails.apiMsg.message = action.error.message;
                state.selectedReceiptPrinterDetails.apiMsg.status = action.meta.requestStatus;
            })

        //------------------------------- getReceiptPrinterACTION end -------------------------

    },
});

export const { RESET, ResetReceiptPrinterDetailsState } = receiptPrinterReducer.actions;
export default receiptPrinterReducer.reducer;
