import React, { useState, useEffect } from 'react'
import AppRouters from './config/router/AppRouters';
import AuthRouters from './config/router/AuthRouters';
import { ToastContainer } from 'react-toastify';
import { Alert } from './component/Modal';
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { StateCompleted, StateHasRecords, StateRejected } from './helper/functions/general-func';
import { ReducerStateContant } from "./helper/constants/general-const";
import { getRestaurantRecordsACTION } from "./redux-store/actions/restaurantsActions";
import { logoutACTION } from './redux-store/actions/authActions'
import { EnumskeyValue } from "./helper/constants/emuns-const";
import * as Toast from './component/Toast'
import { signalRInitiated } from './hooks/signalR'

const signalR = require("@microsoft/signalr");


const App = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { isLoggedIn, usersInfo } = useSelector((state) => state.authReduce);
  const restaurantsReducer = useSelector((state) => state.restaurantsReducer);
  const [authorizedAccess, setAuthorizedAccess] = useState(ReducerStateContant.pending);
  const [restaurantID, setRestaurantID] = useState(null);
 
  const searchParams = new URLSearchParams(location.search);
  // const restaurantID = restaurantsReducer.restaurant.data.records.filter(
  //   (item) => item.selected === true
  // )[0].id;

  useEffect(() => {
    if (window.location.pathname === "/" || window.location.pathname === "") {
      import('./css/bootstrap.min.css');
      import('./fonts/css/all.min.css');
      import("slick-carousel/slick/slick.css");
      import("slick-carousel/slick/slick-theme.css");
      import('react-toastify/dist/ReactToastify.css');
      import("./css/style.css");
      import('./css/landing-page.css');
    }
    else {
      import('./App.css');
      import('./style.css');
      import('react-toastify/dist/ReactToastify.css');

      let link = document.querySelector("link[rel~='icon']");
      if (!link) {
        link = document.createElement('link');
        link.rel = 'icon';
        document.getElementsByTagName('head')[0].appendChild(link);
      }
      link.href = require("./assets/logoOnlogin.jpeg");
    }
  }, [])

  useEffect(() => {
    if (searchParams.get("token") && window.location.pathname.indexOf("/admin/login")!="-1") {
      setAuthorizedAccess(null);
      dispatch(logoutACTION())
        .then((d) => {

        })
        .catch(() => {

        });
    }


  }, [])

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(getRestaurantRecordsACTION({ device: 1 }));
    }
  }, [isLoggedIn]);
  useEffect(() => {

    if (StateCompleted(restaurantsReducer.restaurant) && StateHasRecords(restaurantsReducer.restaurant)) {
      setAuthorizedAccess(ReducerStateContant.fulfilled);

      let tmpRestaurantID = restaurantsReducer.restaurant.data.records.filter(
        (item) => item.selected === true
      )[0]?.id;
      setRestaurantID(tmpRestaurantID);
      // console.log('signalRInitiated ')
      signalRInitiated(tmpRestaurantID)
    }
    if (StateRejected(restaurantsReducer.restaurant)) {
      setAuthorizedAccess(ReducerStateContant.rejected)
    }

  }, [restaurantsReducer.restaurant])
  useEffect(() => {
    window.addEventListener('online', function (e) {
      console.log('And we\'re back :).');
    }, false);

    window.addEventListener('offline', function (e) {
      console.log('Connection is down.');
    }, false);
  }, [])
  return (
    <>
      {
        isLoggedIn ? <AppRouters authorizedAccess={authorizedAccess} active={isLoggedIn} restaurantID={restaurantID} usersInfo={usersInfo} /> : <AuthRouters />
      }
      <ToastContainer />
      <Alert />
    </>
  )
}

export default App;