import { createSlice } from "@reduxjs/toolkit";
import { productMasterBulkGetAllACTION, productMasterBulkUpdateACTION } from "../../actions/settings/productMasterBulkAction";
import { Enums } from "../../../helper/constants/emuns-const";

const INITIAL_STATE = {

    productBulk: {
        data: { records: [] },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    updateBulk: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },

};

const productBulkReducer = createSlice({
    name: "productBulk",
    initialState: INITIAL_STATE,
    reducers: {

        RESET(state, action) {
            state = INITIAL_STATE;
        },
        ResetProductBulkUpdateState(state, action) {
            state.updateBulk = INITIAL_STATE.updateBulk;
        }
    },

    extraReducers: (builder) => {
        builder.addCase(productMasterBulkGetAllACTION.fulfilled, (state, action) => {
            state.productBulk.data = action.payload.data;
            state.productBulk.apiMsg.status = action.meta.requestStatus;
            state.productBulk.apiMsg.message = action.payload.message.message;
        });
        builder.addCase(productMasterBulkGetAllACTION.pending, (state, action) => {
            state.productBulk.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(productMasterBulkGetAllACTION.rejected, (state, action) => {
            state.productBulk.apiMsg.message = action.error.message;
            state.productBulk.apiMsg.status = action.meta.requestStatus;
        });


        builder.addCase(productMasterBulkUpdateACTION.fulfilled, (state, action) => {
            //console.log("receiptPrinterChangeStatusACTION", action);
            state.receiptPrinters.data.records = state.receiptPrinters.data.records.map((item) => {
                return {
                    ...item,
                    active: action.payload.id === item.id ? !item.active : item.active
                }
            })
            state.productBulk.apiMsg.status = action.meta.requestStatus;
            state.productBulk.apiMsg.message = action.payload.message.message;
        });
        builder.addCase(productMasterBulkUpdateACTION.pending, (state, action) => {
            //console.log("receiptPrinterChangeStatusACTION pending", action);
            state.productBulk.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(productMasterBulkUpdateACTION.rejected, (state, action) => {
            //console.log("receiptPrinterChangeStatusACTION rejected", action);
            state.productBulk.apiMsg.message = action.error.message;
            state.productBulk.apiMsg.status = action.meta.requestStatus;
        });
    }
});

export const { RESET, ResetProductBulkUpdateState } = productBulkReducer.actions;
export default productBulkReducer.reducer;